<template>
    <div>
        <update-view-header
            :headline="editedEntity.status === 'draft' ? $t('Submit survey') : $t('View survey')"
            button-path="/surveys"
            :button-text="$t('Back to overview')"
        />


        <div class="flex justify-content-between align-items-center">
            <div>
                <button
                    class="p-button p-button-secondary mr-4 mb-4"
                    :style="{'visibility': getIndexOfCurrentSteps === 0 ? 'hidden': ''}"
                    @click="toRoute(-1)"
                >
                    {{$t('Back')}}
                </button>
            </div>
            <steps
                style="flex: 1 1 auto;"
                class="my-6"
                :model="steps"
                :readonly="false"
                :exact="false"
            />
            <div>
                <button
                    class="p-button p-button-secondary ml-4 mb-4"
                    :style="{'visibility': getIndexOfCurrentSteps === 5 ? 'hidden': ''}"
                    @click="toRoute(1)"
                >
                    {{ $t('Forward') }}
                </button>
            </div>
        </div>


        <div class="surface-card surface-border mb-4"
             v-if="surveyParts.length > 0"
             :class="{'border-1 p-6': $route.name !== 'Structure' && $route.name !== 'Invest'}"
        >
            <router-view
                :survey-parts="surveyParts"
                :survey="editedEntity"
                :readonly="editedEntity.userCanUpdate"
            />
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Steps from "primevue/steps";
    import UpdateView from "@/mixin/UpdateView";
    import UpdateViewHeader from "@/components/view/UpdateViewHeader";

    export default {
        mixins: [UpdateView],
        components: {
            UpdateViewHeader,
            Button,
            Steps
        },
        data() {
            return {
                surveyParts: [],
            }
        },
        computed: {
            nextStepPossible() {
                return true;
            },
            prevStepPossible() {
                return true;
            },
            getIndexOfCurrentSteps() {
                return this.steps.map(e => e.to).indexOf(this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1));
            },
            steps: function () {
                let steps = [
                    {
                        label: this.$t('General structural data'),
                        to: 'structure'
                    },
                    {
                        label: this.$t('HR data'),
                        to: 'human-resource'
                    },
                    {
                        label: this.$t('Invest'),
                        to: 'invest'
                    },
                    {
                        label: this.$t('Audits'),
                        to: 'audits'
                    },
                    {
                        label: this.$t('Business data'),
                        to: 'business-data'
                    },
                ];
                if (this.editedEntity.status === 'draft') {
                    steps.push({label: this.$t('Submission'), to: 'finish'});
                }
                return steps;
            }
        },
        methods: {
            toRoute(move = 1) {
                this.$router.push(this.$route.path.substr(0, this.$route.path.lastIndexOf('/')) + '/' + this.steps[this.getIndexOfCurrentSteps + move].to);
            },
            loadSurveyParts() {
                this.$http.get('survey-parts', {
                    params: {
                        survey: this.editedEntity['@id']
                    }
                }).then(response => {
                    this.surveyParts = response.data['hydra:member'];
                })
            },
            getApiEndpoint() {
                return 'surveys';
            },
        },
        watch: {
            'editedEntity.id': function () {
                this.loadSurveyParts();
            }
        }
    }
</script>
